import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import AttachFileIcon from '@mui/icons-material/AttachFile';
import axios from 'axios';
import Conf from '../../config.json';
import ErrorBar from '../ErrorBar/ErrorBar';
import ConsentForm from '../../assets/Vodacom Bursary Parental Consent Form.pdf';



function Star() {
    return (<span style={{ color: "#E60000" }}>*</span>);
}

function truncate(str, n) {
    return (str.length > n) ? str.slice(0, n - 1) + '...' : str;
};


function SupportingDocuments({ props }) {
    const [error, setError] = React.useState("");

    const handleNext = (ev) => {
        if (!props.supportingDocumentsValidated) {
            setError("Please complete mandatory fields");
        } else {
            props.updateBursar();
            props.setExpanded(99);
        }
    };

    const upload = (file) => {
        const endpoint = `${Conf.api.applicant_api}/upload`;
        const apiKey = Conf.api.xapikey;
        const config = {
            headers: {
                "x-api-key": apiKey,
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
            }
        };
        props.setWaiting(true);
        axios.post(endpoint, file, config)
            .then(function (response) {
                if (response.status === 200) {
                    props.setError("");
                }
                props.setWaiting(false);
            })
            .catch(function (error) {
                props.setWaiting(false);
                props.setError(error.response.data.message);
            });
    };

    const uploadBase64 = (file) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            const fileData = {
                "name": file.name,
                "data": reader.result
            }
            upload(fileData);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    };

    const uploadIdHandler = (event) => {
        const file = event.target.files[0];
        props.setFileId(file.name);
        uploadBase64(file);
    };

    const uploadGrade11Handler = (event) => {
        const file = event.target.files[0];
        props.setFileGrade11(file.name);
        uploadBase64(file);
    };

    const uploadGrade12Handler = (event) => {
        const file = event.target.files[0];
        props.setFileGrade12(file.name);
        uploadBase64(file);
    };

    const uploadProofConsentHandler = (event) => {
        const file = event.target.files[0];
        props.setFileProofConsent(file.name);
        uploadBase64(file);
    };

    const uploadFileAcademicRecordHandler = (event) => {
        const file = event.target.files[0];
        props.setFileAcademicRecord(file.name);
        uploadBase64(file);
    };

    return (
        <Box sx={{ backgroundColor: "#FFFFFF", padding: 2, borderRadius: 2, mt: 3, boxShadow: 3 }}>
            {error &&
                <ErrorBar props={{ error: error, setError: setError }} />
            }
            <Grid container columns={16} sx={{ color: "#282828" }}>
                <Grid item sm={8} sx={{ textAlign: "left" }}>
                    <Typography sx={{ fontSize: 18, fontWeight: 500 }}>
                        Supporting Documents
                    </Typography>
                </Grid>
                <Grid item sm={8} sx={{ textAlign: "right" }}>
                    <Typography sx={{ color: "#666666", fontSize: 16, fontWeight: 500 }}>
                        Section 7 of {props.sections}
                    </Typography>
                </Grid>
            </Grid>
            <Divider sx={{ mt: 3, mb: 3 }} />
            <Grid container columns={16} spacing={3} padding={2}>

                <Grid item sm={16} sx={{ textAlign: "left" }}>
                    <Box sx={{
                        display: 'flex',
                        backgroundColor: '#EBEDEF',
                        justifyContent: 'space-between',
                        borderRadius: 3
                    }}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            p: 1
                        }}>
                            <Typography sx={{ marginTop: 'auto', marginBottom: 'auto', fontSize: 18, fontWeight: 500, color: '#08081B' }}>
                                Upload ID<Star />{(props.fileId) && <Box component="span" sx={{ m: 2, p: 2, backgroundColor: "#F8F8F8	" }}>{props.fileId}</Box>}
                            </Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            p: 1,
                            borderRadius: 1,
                        }}>
                            <Button
                                component="label"
                                variant="outlined"
                                sx={{ mt: 3, mb: 2, width: 200 }}
                                style={{ borderRadius: 68, height: 48, fontSize: 16, width: 200, borderWidth: 2, borderColor: '#08081B' }}
                            >
                                <AttachFileIcon /><Typography sx={{ fontSize: 18, fontWeight: 500, color: '#08081B' }}>
                                    Attach Document
                                </Typography>
                                <input
                                    type="file"
                                    onChange={uploadIdHandler}
                                    hidden
                                />
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                {props.tertiaryinstitution === 'Yes' &&
                <Grid item sm={16} sx={{ textAlign: "left" }}>
                    <Box sx={{
                        display: 'flex',
                        backgroundColor: '#EBEDEF',
                        justifyContent: 'space-between',
                        borderRadius: 3
                    }}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            p: 1
                        }}>
                            <Typography sx={{ marginTop: 'auto', marginBottom: 'auto', fontSize: 18, fontWeight: 500, color: '#08081B' }}>
                                Upload Academic records<Star />{(props.fileAcademicRecord) && <Box component="span" sx={{ m: 2, p: 2, backgroundColor: "#F8F8F8	" }}>{props.fileAcademicRecord}</Box>}
                            </Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            p: 1,
                            borderRadius: 1,
                        }}>
                            <Button
                                component="label"
                                variant="outlined"
                                sx={{ mt: 3, mb: 2, width: 200 }}
                                style={{ borderRadius: 68, height: 48, fontSize: 16, width: 200, borderWidth: 2, borderColor: '#08081B' }}
                            >
                                <AttachFileIcon /><Typography sx={{ fontSize: 18, fontWeight: 500, color: '#08081B' }}>
                                    Attach Document
                                </Typography>
                                <input
                                    type="file"
                                    onChange={uploadFileAcademicRecordHandler}
                                    hidden
                                />
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                }
                {props.matriculated === 'No' &&
                    <Grid item sm={16} sx={{ textAlign: "left" }}>
                        <Box sx={{
                            display: 'flex',
                            backgroundColor: '#EBEDEF',
                            justifyContent: 'space-between',
                            borderRadius: 3
                        }}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                p: 1
                            }}>
                                <Typography sx={{ marginTop: 'auto', marginBottom: 'auto', fontSize: 18, fontWeight: 500, color: '#08081B' }}>
                                    Upload High School Academic Records | Grade 11 <Star />{(props.fileGrade11) && <Box component="span" sx={{ m: 2, p: 2, backgroundColor: "#F8F8F8	" }}>{truncate(props.fileGrade11, 18)}</Box>}
                                </Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                p: 1,
                                borderRadius: 1,
                            }}>
                                <Button
                                    component="label"
                                    variant="outlined"
                                    sx={{ mt: 3, mb: 2, width: 200 }}
                                    style={{ borderRadius: 68, height: 48, fontSize: 16, width: 200, borderWidth: 2, borderColor: '#08081B' }}
                                >
                                    <AttachFileIcon /><Typography sx={{ fontSize: 18, fontWeight: 500, color: '#08081B' }}>
                                        Attach Document
                                    </Typography>
                                    <input
                                        type="file"
                                        onChange={uploadGrade11Handler}
                                        hidden
                                    />
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                }
                {props.matriculated === 'Yes' &&
                    <Grid item sm={16} sx={{ textAlign: "left" }}>
                        <Box sx={{
                            display: 'flex',
                            backgroundColor: '#EBEDEF',
                            justifyContent: 'space-between',
                            borderRadius: 3
                        }}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                p: 1
                            }}>
                                <Typography sx={{ marginTop: 'auto', marginBottom: 'auto', fontSize: 18, fontWeight: 500, color: '#08081B' }}>
                                    Upload High School Academic Records | Grade 12 <Star />{(props.fileGrade12) && <Box component="span" sx={{ m: 2, p: 2, backgroundColor: "#F8F8F8	" }}>{truncate(props.fileGrade12, 18)}</Box>}
                                </Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                p: 1,
                                borderRadius: 1,
                            }}>
                                <Button
                                    component="label"
                                    variant="outlined"
                                    sx={{ mt: 3, mb: 2, width: 200 }}
                                    style={{ borderRadius: 68, height: 48, fontSize: 16, width: 200, borderWidth: 2, borderColor: '#08081B' }}
                                >
                                    <AttachFileIcon /><Typography sx={{ fontSize: 18, fontWeight: 500, color: '#08081B' }}>
                                        Attach Document
                                    </Typography>
                                    <input
                                        type="file"
                                        onChange={uploadGrade12Handler}
                                        hidden
                                    />
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                }

                {props.user.over18 === "no" &&
                    <Grid item sm={16} sx={{ textAlign: "left" }}>
                        <Box sx={{
                            display: 'flex',
                            backgroundColor: '#EBEDEF',
                            justifyContent: 'space-between',
                            borderRadius: 3
                        }}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                p: 1
                            }}>
                                <Typography noWrap sx={{ marginTop: 'auto', marginBottom: 'auto', fontSize: 18, fontWeight: 500, color: '#08081B' }}>
                                    Upload Parental/ Guardian Consent form<Star />{(props.fileProofConsent) && <Box component="span" sx={{ m: 2, p: 2, backgroundColor: "#F8F8F8	" }}>{truncate(props.fileProofConsent, 18)}</Box>}
                                </Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                p: 1,
                                borderRadius: 1,
                            }}>
                                <Button
                                    component="label"
                                    variant="outlined"
                                    sx={{ mt: 3, mb: 2, width: 200 }}
                                    style={{ borderRadius: 68, height: 48, fontSize: 16, width: 200, borderWidth: 2, borderColor: '#08081B' }}
                                >
                                    <AttachFileIcon /><Typography sx={{ fontSize: 18, fontWeight: 500, color: '#08081B' }}>
                                        Attach Document
                                    </Typography>
                                    <input
                                        type="file"
                                        onChange={uploadProofConsentHandler}
                                        hidden
                                    />
                                </Button>
                            </Box>
                        </Box>
                        <Typography>
                            Download <a href={ConsentForm} target="_blank" rel="noreferrer">Parental/ Guardian Consent form *</a>
                        </Typography>

                    </Grid>
                }
            </Grid>
            <Box sx={{ width: "100%" }} align="right">
                <Button
                    variant="filled"
                    sx={{ mr: 3 }} style={{ borderRadius: 68, width: 100, fontSize: 16, fontWeight: 700, backgroundColor: 'black', color: 'white' }}
                    onClick={handleNext}
                >
                    Next
                </Button>
            </Box>
        </Box>
    );
}


export default SupportingDocuments;