import * as React from 'react';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import Config from '../../config.json';
import ConsentForm from '../../assets/Vodacom Bursary Parental Consent Form.pdf';

import './Register.css';


import { createTheme, ThemeProvider } from '@mui/material/styles';


const theme = createTheme({
    palette: {
        primary: {
            main: '#393949',
        }
    },
    typography: {
        button: {
            textTransform: 'none'
        }
    }
});

const submitTheme = createTheme({
    palette: {
        primary: {
            main: '#E60000',
        }
    },
    typography: {
        button: {
            textTransform: 'none'
        }
    }
});

const sxFormLabel = { color: "#08081B", fontSize: 16, fontWeight: 700, mb: 1 }

function Star() {
    return (<span style={{ color: "#E60000" }}>*</span>);
}

function validatePassword(password) {
    const numberOfAlphaNumeric = (password.match(/\w/g) || []).length;
    const numberOfUppercase = (password.match(/[A-Z]/g) || []).length;
    const numberOfNumeric = (password.match(/[\d]/g) || []).length;
    const numberOfSpecial = (password.match(/[^\w]/g) || []).length;
    if (numberOfAlphaNumeric < 4) return false;
    if (numberOfUppercase < 1) return false;
    if (numberOfNumeric < 2) return false;
    if (numberOfSpecial < 2) return false;
    return true;
}


function Register({ props }) {
    const [showWhyThisMatter, setShowWhyThisMatter] = React.useState(false);
    const [over18, setOver18] = React.useState("");                                  // Over 18?
    const [over18Error, setOver18Error] = React.useState(false);
    const [disclaimer, setDisclaimer] = React.useState("no");                       // Disclaimer
    const [disclaimerError, setDisclaimerError] = React.useState(false);
    const [course, setCourse] = React.useState("none");                             // Course
    const [courseError, setCourseError] = React.useState(false);
    const [rsaid, setRsaid] = React.useState("");                                   // RSAID
    const [rsaidError, setRsaidError] = React.useState(false);
    const [firstname, setFirstname] = React.useState("")                            // Firstname
    const [firstnameError, setFirstnameError] = React.useState(false);
    const [lastname, setLastname] = React.useState("")                              // Lastname
    const [lastnameError, setLastnameError] = React.useState(false);
    const [mobile, setMobile] = React.useState("")                                  // Mobile number
    const [mobileError, setMobileError] = React.useState(false);
    const [email, setEmail] = React.useState("")                                    // eMail Address
    const [emailError, setEmailError] = React.useState(false);
    const [confirmEmail, setConfirmEmail] = React.useState("")                      // Confrim eMail Address
    const [confirmEmailError, setConfirmEmailError] = React.useState(false);
    const [password, setPassword] = React.useState("")                              // Password
    const [passwordError, setPasswordError] = React.useState(false);
    const [confirmPassword, setConfirmPassword] = React.useState("")                // Confrim Password
    const [confirmPasswordError, setConfirmPasswordError] = React.useState(false);
    const [doSubmit, setDoSubmit] = React.useState(false);
    
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
    const handleClickShowWhyThisMatter = () => setShowWhyThisMatter((show) => !show);
    const handleMouseDown = (event) => {
        event.preventDefault();
    };

    const [validated, setValidated] = React.useState(false);

    const textWhyThisMatter = () => {
        return (
            <span>:
                <ul>
                    <li>Parent or guardian can consent to the processing.</li>
                    <li>The processing is necessary for the establishment, exercise or defence of a right or obligation in law (which includes obligations of international public law)</li>
                    <li>The personal information is being used for historical, statistical or research purposes if it services a public interest and it is impossible (or would require a disproportionate effort to for consent)</li>
                </ul>
            </span>
        )
    };

    React.useEffect(() => {

        if (doSubmit) {
            setDoSubmit(false);
            const errOver18 = (over18 === "");
            const errDisclaimer = (disclaimer === "no");
            const errCourse = (course === "none"); 
            const errRsaid = (rsaid === "");
            const errFirstname = (firstname === "");
            const errLastname = (lastname === "");
            const errMobile = (!(mobile.match(/^0\d{9}$/) || mobile.match(/^\+\d{11}$/)));
            const errEmail = (!(email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/) && (email === confirmEmail)));
            const errPassword = (!(validatePassword(password) && (password === confirmPassword)));
            const errConfirmPassword = ((!confirmPassword)||(password !== confirmPassword));
            const errConfirmEmail = ((!confirmEmail)||(email !== confirmEmail));
            const errors = (
                errOver18 ||
                errDisclaimer ||
                errCourse ||
                errRsaid ||
                errFirstname ||
                errLastname ||
                errMobile ||
                errPassword ||
                errConfirmPassword ||
                errConfirmEmail
            );
            
            setOver18Error(errOver18);
            setDisclaimerError(errDisclaimer);
            setCourseError(errCourse);
            setRsaidError(errRsaid);
            setFirstnameError(errFirstname);
            setLastnameError(errLastname);
            setMobileError(errMobile);
            setEmailError(errEmail);
            setPasswordError(errPassword);
            setConfirmEmailError(errConfirmEmail);
            setConfirmPasswordError(errConfirmPassword);

            if (!errors) {
                const endpoint = `${Config.api.applicant_api}/bursar`;
                const apiKey = Config.api.xapikey;
                const config = {
                    headers: {
                        "x-api-key": apiKey,
                        "Content-Type": "application/json"
                    }
                }
                const payload = {
                    firstname: firstname,
                    lastname: lastname,
                    mobile: mobile,
                    email: email,
                    password: password,
                    rsaid: rsaid,
                    coursetype: props.activeBursary.Courses[course],
                    over18: over18,
                    disclaimer: disclaimer
                };
                props.setWaiting(true);
                axios.post(endpoint, payload, config)
                    .then(function (response) {
                        props.setWaiting(false);
                        if (response.status === 200) {
                            props.setError("");
                            props.setCurrentPage("Verify");
                        }
                    })
                    .catch(function (error) {
                        props.setWaiting(false);
                        props.setError(error.response.data.message);
                        setValidated(false);
                        console.log(JSON.stringify(error.response.data));
                    });
            } else {
                props.setError("Please complete the mandatory or invalid field/s indicated");
            }
        }
    }, [
        setValidated,
        course,
        disclaimer,
        email,
        firstname,
        lastname,
        mobile,
        over18,
        password,
        props,
        rsaid,
        validated,
        over18Error,
        disclaimerError,
        courseError,
        rsaidError,
        firstnameError,
        lastnameError,
        mobileError,
        emailError,
        passwordError,
        confirmEmail,
        confirmPassword,
        setDoSubmit,
        doSubmit
    ]);

    return (
        <Container component="main" sx={{ mt: 3, alignItems: "left" }}  >
            <ThemeProvider theme={theme} >
                <CssBaseline />
                <Typography style={{ textAlign: "left", fontSize: 32, fontWeight: 700 }}>
                    Register to apply
                </Typography>
                <Box
                    component="form"
                    noValidate
                    sx={{
                        mt: 3,
                        padding: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left',
                        backgroundColor: '#F5F6F7'
                    }}
                >
                    <Box sx={{ alignItems: 'left' }}>
                        <FormControl error={over18Error}>
                            <FormLabel id="minor-status-radio-buttons-group-label" sx={sxFormLabel}>Minor Status<Star /></FormLabel>
                            <RadioGroup
                                aria-labelledby="minor-status-radio-buttons-group-label"
                                name="minor-status-radio-buttons-group"
                                onChange={(event) => { setOver18(event.target.value) }}
                            >
                                <FormControlLabel value="yes" control={<Radio />} label="Yes, I am 18 years old or older." />
                                <FormControlLabel value="no" control={<Radio />} label="No, I am not over 18 years old and I have obtained parental consent. " />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    <Box sx={{ backgroundColor: "#FFFFFF", padding: 2, borderRadius: 2 }}>
                        <p style={{ fontWeight: 700, fontSize: 16 }}>Why does this matter?</p>
                        <div>
                            Section 34 Prohibition on processing personal information of children.
                            Processing the personal information of children is prohibited unless one
                            of the following justifications are present{showWhyThisMatter && textWhyThisMatter()}...<Button sx={{ ml: 0, fontWeight: 700, fontSize: 16 }} onMouseDown={handleMouseDown} onClick={handleClickShowWhyThisMatter}>
                                Read {showWhyThisMatter ? "less" : "more"}
                            </Button>
                        </div>
                    </Box>
                    {(over18 === "no") &&
                        <Box sx={{ mt: 3, alignItems: 'left' }}>
                            <FormGroup>
                                <FormControl error={disclaimerError}>
                                    <FormLabel id="checkbox-disclaimer-label" sx={{ fontSize: 16, fontWeight: 700, mb: 1 }}>Please note:<Star /></FormLabel>
                                    <FormControlLabel control={<Checkbox onChange={(event) => { setDisclaimer(event.target.checked ? "yes" : "no") }} />} label={
                                        <div>You are required to obtain parental consent by downloading the <a href={ConsentForm} target="_blank" rel="noreferrer">parental consent form</a> and attach the completed consent form during the application process</div>
                                    } />
                                </FormControl>
                            </FormGroup>
                        </Box>
                    }
                    {(over18 === "yes") &&
                        <Box sx={{ mt: 3, alignItems: 'left' }}>
                            <FormGroup>
                                <FormControl error={disclaimerError}>
                                    <FormLabel id="checkbox-disclaimer-label" sx={{ fontSize: 16, fontWeight: 700, mb: 1 }}>Please note:<Star /></FormLabel>
                                    <FormControlLabel control={<Checkbox onChange={(event) => { setDisclaimer(event.target.checked ? "yes" : "no") }} />} label={
                                        <div>I declare that to the best of my knowledge all the information on this form is true and correct. By clicking register, you have read and accept the <a href={"https://www.vodacom.co.za/vodacom/privacy-policy/terms?icmp=Home/Footer/PrivacyPolicy"} target="_blank" rel="noreferrer">Vodacom Privacy Policy</a></div>
                                    } />
                                </FormControl>
                            </FormGroup>
                        </Box>
                    }
                    <Box sx={{ backgroundColor: "#FFFFFF", padding: 2, borderRadius: 2, mt: 3, boxShadow: 3 }}>
                        <Grid container columns={16} spacing={3}>
                            <Grid item sm={8} >
                                <FormControl fullWidth>
                                    <FormLabel id="label-course" sx={sxFormLabel}>Select course type <Star /></FormLabel>
                                    <Select
                                        id="course"
                                        value={course}
                                        onChange={(event) => { setCourse(event.target.value) }}
                                        error={courseError}
                                        required
                                    >
                                        <MenuItem value="none" disabled>
                                            <em>{(props.activeBursary.Courses.length) ? "- Select -" : "Loading ..."}</em>
                                        </MenuItem>
                                        {props.activeBursary.Courses.map((course, index) => (
                                            <MenuItem key={index} value={index}>
                                                <Typography>{course}</Typography>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item sm={8}>

                            </Grid>
                            <Grid item sm={8}>
                                <FormControl fullWidth>
                                    <FormLabel id="label-idnumber" sx={sxFormLabel}>ID Number <Star /></FormLabel>
                                    <TextField
                                        required
                                        fullWidth
                                        error={rsaidError}
                                        label="Enter ID number"
                                        onChange={(event) => { setRsaid(event.target.value) }}
                                        type="number"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item sm={8}>
                                <FormControl fullWidth>
                                    <FormLabel id="label-cellphonenumber" sx={sxFormLabel}>Cell Phone Number <Star /></FormLabel>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Enter cellphone number"
                                        onChange={(event) => { setMobile(event.target.value) }}
                                        error={mobileError}
                                        type="number"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item sm={8}>
                                <FormControl fullWidth>
                                    <FormLabel id="label-firstname" sx={sxFormLabel}>Name <Star /></FormLabel>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Enter name"
                                        onChange={(event) => { setFirstname(event.target.value) }}
                                        error={firstnameError}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item sm={8}>
                                <FormControl fullWidth>
                                    <FormLabel id="label-surname" sx={sxFormLabel}>Surname <Star /></FormLabel>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Enter surname"
                                        onChange={(event) => { setLastname(event.target.value) }}
                                        error={lastnameError}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item sm={8}>
                                <FormControl fullWidth>
                                    <FormLabel id="label-email" sx={sxFormLabel}>Email Address <Star /></FormLabel>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Enter email address"
                                        onChange={(event) => { setEmail(event.target.value) }}
                                        error={emailError}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item sm={8}>
                                <FormControl fullWidth>
                                    <FormLabel id="label-confirm-email" sx={sxFormLabel}>Confirm Email Address <Star /></FormLabel>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Enter email address"
                                        onChange={(event) => { setConfirmEmail(event.target.value) }}
                                        error={confirmEmailError}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item sm={8}>
                                <FormLabel id="label-password" sx={sxFormLabel}>Password <Star /></FormLabel>
                                <FormControl fullWidth variant="outlined" sx={{ mt: 1 }}>
                                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDown}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        onChange={(event) => { setPassword(event.target.value) }}
                                        error={passwordError}
                                        label="Password"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item sm={8}>
                                <FormLabel id="label-password" sx={sxFormLabel}>Confirm Password <Star /></FormLabel>
                                <FormControl fullWidth variant="outlined" sx={{ mt: 1 }}>
                                    <InputLabel htmlFor="outlined-adornment-confirm-password">Confirm Password</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-confirm-password"
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowConfirmPassword}
                                                    onMouseDown={handleMouseDown}
                                                    edge="end"
                                                >
                                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        onChange={(event) => { setConfirmPassword(event.target.value) }}
                                        error={confirmPasswordError}
                                        label="Confirm Password"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
                                    Your password must include at least:
                                </Typography>
                                <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
                                    4 Alphabetic characters <span style={{ color: "red" }}>|</span> 1 Upper case alphabetic character <span style={{ color: "red" }}>|</span> 2 Numeric characters <span style={{ color: "red" }}>|</span> 2 Special characters
                                </Typography>
                            </Grid>
                        </Grid>
                        <Box justifyContent="center" sx={{ textAlign: "center" }}>
                            <ThemeProvider theme={submitTheme}>
                                <Button
                                    type="button"
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2, width: 200 }}
                                    style={{ borderRadius: 68, height: 48, fontSize: 16 }}
                                    onClick={(ev)=>{setDoSubmit(true);}}
                                >
                                    Register
                                </Button>
                            </ThemeProvider>
                        </Box>
                    </Box>
                </Box>
            </ThemeProvider>
        </Container>
    );
}

export default Register;