import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TestimonialCarousel from '../TestimonialCarousel/TestimonialCarousel';
import Conf from '../../config.json';
import axios from 'axios';

const theme = createTheme({
    palette: {
        primary: {
            main: '#000000',
        }
    },
    typography: {
        button: {
            textTransform: 'none'
        }
    }
});

const submitTheme = createTheme({
    palette: {
        primary: {
            main: '#E60000',
        }
    },
    typography: {
        button: {
            textTransform: 'none'
        }
    }
});

const changeTheme = createTheme({
    palette: {
        primary: {
            main: '#000000',
        }
    },
    typography: {
        button: {
            textTransform: 'none'
        }
    }
});

function ForgotPassword({ props }) {

    const [email, setEmail] = React.useState();
    const [validated, setValidated] = React.useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        const endpoint = `${Conf.api.applicant_api}/password`;
        const apiKey = Conf.api.xapikey;
        const config = {
            headers: {
                "x-api-key": apiKey,
                "Content-Type": "application/json"
            },
            params: {
                "email": email
            }
        };
        props.setWaiting(true);
        axios.get(endpoint, config)
            .then(function (response) {
                if (response.status === 200) {
                    props.setWaiting(false);
                    props.setCurrentPage("PasswordResetSent");
                }
            })
            .catch(function (error) {
                console.log(JSON.stringify(error.response.data));
                props.setError(error.response.data.message);
                props.setWaiting(false);
            });
    };

    React.useEffect(() => {
        setValidated(!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email));
    }, [email]);

    return (
        <Grid container justifyContent="center" height="100vh">
            <Grid item xs sm={6} sx={{ backgroundColor: '#FF0000' }} style={{ background: 'linear-gradient(to right bottom, #9C2AA0, #E60000)' }}>
                <Container sx={{ mt: 15, textAlign: "center" }}>
                    <TestimonialCarousel />
                </Container>
            </Grid>
            <Grid item sm={6} sx={{ backgroundColor: '#FFFFFF' }} >
                <ThemeProvider theme={theme}>
                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Typography sx={{ fontSize: 32, fontWeight: 500 }}>
                                Forgot Password
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 400, mt: 3 }}>
                                Capture the registered email
                            </Typography>
                            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 4, width: "100%" }}>
                                <Grid container spacing={2} columns={18}>
                                    <Grid item sm={18}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="email"
                                            label="Email Address"
                                            name="email"
                                            autoComplete="email"
                                            error={validated}
                                            onChange={(event) => { setEmail(event.target.value) }}
                                        />
                                    </Grid>
                                    <Grid item sm={9}>
                                        <ThemeProvider theme={changeTheme}>
                                            <Button
                                                type="button"
                                                fullWidth
                                                variant="outlined"
                                                sx={{ mt: 3, mb: 2 }}
                                                style={{ borderRadius: 68, height: 48, fontSize: 16 }}
                                                onClick={()=>{props.setCurrentPage("Login")}}
                                            >
                                                Cancel
                                            </Button>
                                        </ThemeProvider>
                                    </Grid>
                                    <Grid item sm={9}>
                                        <ThemeProvider theme={submitTheme}>
                                            <Button
                                                disabled={validated}
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                sx={{ mt: 3, mb: 2 }}
                                                style={{ borderRadius: 68, height: 48, fontSize: 16 }}
                                            >
                                                Send Reset Email
                                            </Button>
                                        </ThemeProvider>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Container>
                </ThemeProvider>
            </Grid>
        </Grid>
    );
}

export default ForgotPassword;