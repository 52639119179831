import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import EmailImage from '../../assets/Email.png';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const submitTheme = createTheme({
    palette: {
        primary: {
            main: '#E60000',
        }
    },
    typography: {
        button: {
            textTransform: 'none'
        }
    }
});

function PasswordResetSent({ props }) {

    const handleClick = (event) => {
        props.setCurrentPage("Login");
    };

    return (
        <Box sx={{ height: "100vh", display: "flex", alignItems: "center" }} justifyContent="center">
            <Box sx={{ textAlign: "center" }}>
                <Box>
                    <img src={EmailImage} alt="reset link sent" style={{width: 200}} />
                </Box>
                <Box>
                    <Typography sx={{ fontSize: 32, fontWeight: 700, mt: 3 }}>
                        Email Sent
                    </Typography>
                </Box>
                <Box>
                    <Typography sx={{ fontSize: 14, mt: 3 }}>
                        A password reset link has been sent to the captured email
                    </Typography>
                </Box>
                <Box>
                    <ThemeProvider theme={submitTheme}>
                        <Button
                            variant="contained"
                            sx={{ mt: 3, mb: 2, width: 200 }}
                            style={{ borderRadius: 68, height: 48, fontSize: 16, width: "100%" }}
                            onClick={handleClick}
                        >
                            Back to Login
                        </Button>
                    </ThemeProvider>
                </Box>
            </Box>
        </Box>
    );
}

export default PasswordResetSent;