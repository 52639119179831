import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import CreateIcon from '@mui/icons-material/Create';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const theme = createTheme({
    palette: {
        primary: {
            main: '#393949',
        }
    },
    typography: {
        button: {
            textTransform: 'none'
        }
    }
});

const GradientInfoIcon = () => (
    <>
        <svg width={0} height={0}>
            <linearGradient id="linearColors" x1={0} y1={1} x2={1} y2={1}>
                <stop offset={0} stopColor="rgba(168, 180, 0, 1)" />
                <stop offset={1} stopColor="rgba(0, 176, 202, 1)" />
            </linearGradient>
        </svg>
        <CheckCircleRoundedIcon sx={{ fill: "url(#linearColors)", fontSize: "80px" }} />
    </>
)

function Verified({ props }) {

    return (
        <Box>
            <ThemeProvider theme={theme} >
                <CssBaseline />
                <Box sx={{ mt: 3, display: "flex", alignItems: "center" }} justifyContent="center">
                    <Box sx={{ textAlign: "center" }}>
                        <Box>
                            <Typography>
                                <GradientInfoIcon />
                            </Typography>
                        </Box>
                        <Box>
                            <Typography sx={{ fontSize: 32, fontWeight: 700, mt: 3 }}>
                                Thank you for taking the time to apply
                            </Typography>
                        </Box>
                        <Box sx={{ m: 10, fontSize: 14, mt: 3 }}>
                            <p><strong>Please note:</strong> You are allowed to edit and resubmit your application until the bursary period closes.</p>

                            <p>The applications run from 1 June to 31 August of every year. Applications will only be reviewed after the closing date. Vodacom will revert no
                                later than 15th December on the outcome of your application.</p>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ ml: 5, mr: 5 }}>
                    <Typography sx={{ fontSize: 32, fontWeight: 700, mt: 3 }}>
                        Application Form
                    </Typography>
                    <Box sx={{ boxShadow: "0px 5px 20px rgba(12, 12, 26, 0.15)", p: 2, borderRadius: 3, mt: 3 }}>
                        <Grid container columns={18}>
                            <Grid item xs={18} sx={{ textAlign: "left" }}>
                                <Typography sx={{ fontWeight: 700 }}>
                                    List of Application forms
                                </Typography>
                            </Grid>
                            <Grid item xs={18}>
                                <Divider sx={{ mt: 3, mb: 3, color: "#000000" }} />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>
                                    Submitted application by {props.user.id}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>
                                    {props.user.updated}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    onClick={() => props.setCurrentPage("Application Form")}
                                    variant='outlined'
                                    sx={{ borderRadius: 8, borderWidth: 2 }}
                                >
                                    <Box sx={{ display: "inline-flex" }}>
                                        <CreateIcon />
                                        <Typography sx={{ fontWeight: 700 }}>Edit</Typography>
                                    </Box>
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </ThemeProvider>
        </Box>
    );
}

export default Verified;