import Grid from '@mui/material/Grid';
import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';

function SuccessBar({ props }) {
    return (
        <Grid container alignItems="center" direction="row" sx={{ backgroundColor: '#000000', color: '#FFFFFF', padding: 2 }}>
            <Grid item xs={10} justifyContent='flex-start' sx={{ fontSize: 14, fontWeight: 'bold', color: 'inherit', textTransform: 'none', padding: 0, display: 'inline-flex' }}>
                <InfoIcon style={{ marginLeft: 50, marginRight: 10 }} /><span style={{ marginTop: 2 }}>{props.successMessage}</span>
            </Grid>
            <Grid item xs={1} justifyContent='flex-end'>
                <Button onClick={() => { props.setSuccessMessage("") }}
                    sx={{ fontSize: 14, fontWeight: 'bold', color: 'inherit', textTransform: 'none', padding: 0 }}
                >Dismiss</Button>
            </Grid>
        </Grid>
    );
}

export default SuccessBar;