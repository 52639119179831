import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import FormHelperText from '@mui/material/FormHelperText';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TestimonialCarousel from '../TestimonialCarousel/TestimonialCarousel';
import OTPModal from '../OTPModal/OTPModal';
import Conf from '../../config.json';
import axios from 'axios';


const theme = createTheme({
    palette: {
        primary: {
            main: '#000000',
        }
    },
    typography: {
        button: {
            textTransform: 'none'
        }
    }
});

const submitTheme = createTheme({
    palette: {
        primary: {
            main: '#E60000',
        }
    },
    typography: {
        button: {
            textTransform: 'none'
        }
    }
});


function Login({ props }) {

    const [email, setEmail] = React.useState();
    const [emailError, setEmailError] = React.useState("");
    const [password, setPassword] = React.useState();
    const [passwordError, setPasswordError] = React.useState();
    const [showPassword, setShowPassword] = React.useState(false);
    const [partialMobile, setPartialMobile] = React.useState("");

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        if (!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
            setEmailError("Invalid email address");
            return
        } else {
            setEmailError("");
        }

        if (!password) {
            setPasswordError("Please enter a password");
            return
        } else {
            setPasswordError("");
        }

        const endpoint = `${Conf.api.applicant_api}/auth`;
        const apiKey = Conf.api.xapikey;
        const config = {
            headers: {
                "x-api-key": apiKey,
                "Content-Type": "application/json"
            }
        };
        const payload = {
            "email": email,
            "password": password
        };
        props.setWaiting(true);
        axios.post(endpoint, payload, config)
            .then(function (response) {
                if (response.status === 200) {
                    const at = response.data.accessToken;
                    const mobile = response.data.mobile;
                    localStorage.setItem("accessToken", at);
                    setPartialMobile(mobile);
                }
                props.setWaiting(false);
            })
            .catch(function (error) {
                console.log(JSON.stringify(error.response.data));
                props.setError(error.response.data.message);
                props.setWaiting(false);
            });

    };

    return (
        <Grid container justifyContent="center" height="100vh">
            <Grid item xs sm={6} sx={{ backgroundColor: '#FF0000' }} style={{ background: 'linear-gradient(to right bottom, #9C2AA0, #E60000)' }}>
                <Container sx={{ mt: 15, textAlign: "center" }}>
                    <TestimonialCarousel />
                </Container>
            </Grid>
            <Grid item sm={6} sx={{ backgroundColor: '#FFFFFF' }} >
                <ThemeProvider theme={theme}>
                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Typography component="h1" variant="h5">
                                Welcome to the
                            </Typography>
                            <Typography component="h1" variant="h3">
                                Bursar Portal
                            </Typography>
                            <Typography component="h1" variant="h5">
                                Login
                            </Typography>
                            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="email"
                                            label="Email Address"
                                            name="email"
                                            autoComplete="email"
                                            error={emailError ? true : false}
                                            helperText={emailError}
                                            onChange={(event) => { setEmail(event.target.value) }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-password"
                                                type={showPassword ? 'text' : 'password'}
                                                onChange={(event) => { setPassword(event.target.value) }}
                                                error={passwordError ? true : false}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                label="Password"
                                            />
                                            {passwordError && (
                                                <FormHelperText error id="accountId-error">
                                                    {passwordError}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent="flex-start" sx={{ mt: 3 }}>
                                    <Link href="#" onClick={() => { props.setCurrentPage("ForgotPassword") }} variant="body2">
                                        Forgot password
                                    </Link>
                                </Grid>
                                <ThemeProvider theme={submitTheme}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                        style={{ borderRadius: 68, height: 48, fontSize: 16 }}
                                    >
                                        Log in
                                    </Button>
                                </ThemeProvider>
                                {!props.applicationsClosed &&
                                    <Typography variant="body2" align="center">
                                        Not registered yet?
                                        <Link sx={{ ml: 1 }} color="#e60000" href="#" onClick={() => { props.setCurrentPage("Register") }}>
                                            Register
                                        </Link>
                                    </Typography>
                                }
                                <Typography sx={{ mt: 3 }} variant="body2" align="center">
                                    By logging in, you or your parent/guardian have accepted the<br />
                                    <Link sx={{ ml: 1 }} color="#007C92" href="https://www.vodacom.co.za/vodacom/privacy-policy/terms?icmp=Home/Footer/PrivacyPolicy" 
                                        target="_blank" rel="noreferrer">
                                        Vodacom Privacy Policy
                                    </Link>
                                    <br />and the<br />
                                    <Link sx={{ ml: 1 }} color="#007C92" href="#" onClick={() => { props.setCurrentPage("Terms & Conditions") }}>
                                        Bursary Terms and Conditions
                                    </Link>
                                </Typography>
                            </Box>
                        </Box>
                    </Container>
                </ThemeProvider>
            </Grid>
            <OTPModal props={{
                partialMobile: partialMobile,
                open: (partialMobile ? true : false),
                setWaiting: props.setWaiting,
                setError: props.setError,
                setSuccessMessage: props.setSuccessMessage,
                setLoggedIn: props.setLoggedIn
            }} />
        </Grid>
    );
}

export default Login;