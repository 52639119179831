import Grid from '@mui/material/Grid';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

function WaitBar() {
    return (
        <Grid container alignItems="center" direction="row" sx={{ backgroundColor: '#C9CCD4', padding: 2 }}>
            <Grid item xs={10} justifyContent='flex-start' sx={{ fontSize: 14, fontWeight: 'bold', color: 'inherit', textTransform: 'none', padding: 0, display: 'inline-flex' }}>
                <AccessTimeIcon style={{ marginLeft: 50, marginRight: 10 }} /><span style={{ marginTop: 2 }}>Please wait ...</span>
            </Grid>
        </Grid>
    );
}

export default WaitBar;