import React, { useState, useEffect } from 'react';
import './App.css';
import './ResponsiveAppBar'
import Conf from './config.json'
import ResponsiveAppBar from './ResponsiveAppBar';
import Login from './components/Login/Login'
import Register from './components/Register/Register'
import Verify from './components/Verify/Verify'
import Verified from './components/Verified/Verified'
import Submitted from './components/Submitted/Submitted'
import Status from './components/Status/Status'
import Application from './components/Application/Application'
import ApplicationForm from './components/ApplicationForm/ApplicationForm'
import ForgotPassword from './components/ForgotPassword/ForgotPassword'
import PasswordResetSent from './components/PasswordResetSent/PasswordResetSent'
import ResetPassword from './components/ResetPassword/ResetPassword'
import PasswordUpdated from './components/PasswordUpdated/PasswordUpdated'
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions';
import ApplicationProcess from './components/ApplicationProcess/ApplicationProcess';
import axios from 'axios';
import ClosedModal from './components/ClosedModal/ClosedModal';



function App() {
  const [currentPage, setCurrentPage] = useState('Login');
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [activeBursary, setActiveBursary] = useState({ "Courses": [] });
  const [waiting, setWaiting] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [passwordReset, setPasswordReset] = useState(false);
  const [resetToken, setResetToken] = useState("");
  const [user, setUser] = useState({});
  const [applicationsClosedMessage, setApplicationsClosedMessage] = useState("");
  const [showApplicationsClosedMessage, setShowApplicationsClosedMessage] = useState(true);
  const [applicationsClosed, setApplicationsClosed] = useState(false);
  const [bursaryFetched, setBursaryFetched] = useState(false);
  const [userLoaded, setUserLoaded] = useState(false);
  const [reloadUser, setReloadUser] = useState(false);



  useEffect(() => {
    if (!bursaryFetched) {
      axios.get(`${Conf.api.applicant_api}/bursary`, {
        headers: { 'x-api-key': Conf.api.xapikey }
      }).then(function (response) {
        setBursaryFetched(true);
        if (response.status === 200) {
          setError("");
          setWaiting(false);
          setActiveBursary(response.data);
        }
      })
        .catch(function (error) {
          const errormsg = error.response.data.message;
          const status = error.response.status;
          setWaiting(false);
          setBursaryFetched(true);
          if (status === 404) {
            setApplicationsClosedMessage(error.response.data);
            setApplicationsClosed(true);
          } else {
            setError(errormsg);
          }
        });
    } else {
      //setShowApplicationsClosedMessage(false);
    }
    const accessToken = localStorage.getItem("accessToken");

    if ((loggedIn && Object.keys(user).length === 0) || (reloadUser)) {
      setWaiting(true);
      const endpoint = `${Conf.api.applicant_api}/bursar`;
      const apiKey = Conf.api.xapikey;
      const config = {
        headers: {
          "x-api-key": apiKey,
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
          "Content-Type": "application/json"
        }
      }
      axios.get(endpoint, config)
        .then(function (response) {
          if (response.status === 200) {
            setError("");
            setWaiting(false);
            setUser(response.data);
            setUserLoaded(true);
            if (reloadUser) {
              setReloadUser(false);
            } else {
              if (response.data.status === "Submitted") {
                setCurrentPage("Submitted");
              }
            }
          }
        })
        .catch(function (error) {
          const errormsg = error.response.data.message;
          setWaiting(false);
          if (errormsg.startsWith("invalid ")) {
            localStorage.removeItem("accessToken");
            setLoggedIn(false);
            setCurrentPage("Login");
            setError("You've been logged out");
          } else {
            setError(errormsg);
          }
        });
    }

    if (!loggedIn && accessToken) {
      setLoggedIn(true);
    }

    if (!passwordReset) {
      const searchParams = new URLSearchParams(document.location.search);
      const resetToken = searchParams.get("reset");
      if (resetToken) {
        setPasswordReset(true);
        setResetToken(resetToken);
        setCurrentPage("ResetPassword");
      }
    }

    if (!emailVerified) {
      const searchParams = new URLSearchParams(document.location.search);
      const emailToken = searchParams.get("verify");
      if (emailToken) {
        setWaiting(true);
        const endpoint = `${Conf.api.applicant_api}/verify`;
        const apiKey = Conf.api.xapikey;
        const config = {
          headers: {
            "x-api-key": apiKey,
            "Content-Type": "application/json"
          }
        };
        const payload = {
          "guid": emailToken
        };
        setWaiting(true);
        setEmailVerified(true);
        axios.post(endpoint, payload, config)
          .then(function (response) {
            setWaiting(false);
            if (response.status === 200) {
              setCurrentPage("Verified");
            }
          })
          .catch(function (error) {
            setWaiting(false);
            setError(error.response.data.message);
            console.log(JSON.stringify(error.response.data));
          });
      }
    }

    if (loggedIn && currentPage === "Login") {
      if (user.status === "Submitted") {
        setCurrentPage("Submitted");
      } else {
        setCurrentPage("Application Form");
      }
    }
  }, [currentPage, loggedIn, emailVerified, passwordReset, user, bursaryFetched, reloadUser]);

  const page = () => {
    const props = {
      setError: setError,
      setCurrentPage: setCurrentPage,
      setSuccessMessage: setSuccessMessage,
      setWaiting: setWaiting,
      setLoggedIn: setLoggedIn,
      reloadUser: setReloadUser,
      user: user,
      activeBursary: activeBursary,
      applicationsClosed: applicationsClosed,
      userLoaded: userLoaded
    }

    switch (currentPage) {
      case "Login":
        return (<Login props={props} />);
      case "Register":
        return (<Register props={props} />);
      case "Verify":
        return (<Verify props={props} />);
      case "Verified":
        return (<Verified props={props} />);
      case "Application":
        return (<Application props={props} />);
      case "Application Form":
        return (<ApplicationForm props={props} />);
      case "ForgotPassword":
        return (<ForgotPassword props={props} />);
      case "PasswordResetSent":
        return (<PasswordResetSent props={props} />);
      case "ResetPassword":
        return (<ResetPassword props={{ ...props, resetToken }} />)
      case "PasswordUpdated":
        return (<PasswordUpdated props={{ ...props, resetToken }} />)
      case "Terms & Conditions":
        return (<TermsAndConditions props={{ ...props, activeBursary }} />)
      case "Application Process":
        return (<ApplicationProcess props={props} />)
      case "Submitted":
        return (<Submitted props={props} />)
      case "Application Status":
        return (<Status props={props} />)
      default:
        return (<h2>{currentPage} not implemented.</h2>)
    }
  };

  return (
    <div className="App">
      <ResponsiveAppBar props={{
        currentPage: currentPage,
        setCurrentPage: setCurrentPage,
        loggedIn: loggedIn,
        setLoggedIn: setLoggedIn,
        applicationsClosed: applicationsClosed,
        error: error,
        successMessage: successMessage,
        setError: setError,
        setSuccessMessage: setSuccessMessage,
        waiting: waiting
      }} />
      <div style={{ opacity: waiting ? "0.2" : "1" }}>
        {page()}
      </div>
      <ClosedModal props={{
        open: applicationsClosed && showApplicationsClosedMessage,
        message: applicationsClosedMessage,
        setShowApplicationsClosedMessage: setShowApplicationsClosedMessage
      }} />
    </div>
  );
}

export default App;
