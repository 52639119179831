import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import ClosedImage from '../../assets/Closed.png'


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '16px',
    boxShadow: 24,
    textAlign: "center",
};

export default function ClosedModal({ props }) {
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const handleClose = () => {
        setOpen(false);
        props.setShowApplicationsClosedMessage(false);
    }

    React.useEffect(() => {
        setOpen(props.open);
        setMessage(props.message || "Applications Closed");
    }, [props]);

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} component="form" noValidate>
                    <Box sx={{ textAlign: "right", mr: 2, mt: 2 }}>
                        <IconButton onClick={handleClose}>
                            <CloseIcon sx={{ color: "#8A8D9C" }} />
                        </IconButton>
                    </Box>
                    <Box sx={{p: 4}}>
                        <Typography sx={{ color: "#08081B", fontSize: 26, fontWeight: 700 }}>
                            Applications Closed
                        </Typography>
                        <img src={ClosedImage} alt="applications closed" style={{ maxWidth: "100%" }} />
                        <Typography sx={{fontWeight: 700, fontSize: 16, color: '#000000'}}>
                            {message}
                        </Typography>
                    </Box>
                </Box>
            </Modal>
        </div >
    );
}