import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TestimonialCarousel from '../TestimonialCarousel/TestimonialCarousel';
import Conf from '../../config.json';
import axios from 'axios';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


const theme = createTheme({
    palette: {
        primary: {
            main: '#000000',
        }
    },
    typography: {
        button: {
            textTransform: 'none'
        }
    }
});

const submitTheme = createTheme({
    palette: {
        primary: {
            main: '#E60000',
        }
    },
    typography: {
        button: {
            textTransform: 'none'
        }
    }
});

const changeTheme = createTheme({
    palette: {
        primary: {
            main: '#000000',
        }
    },
    typography: {
        button: {
            textTransform: 'none'
        }
    }
});

const sxFormLabel = { color: "#08081B", fontSize: 16, fontWeight: 700, mb: 1 }

function Star() {
    return (<span style={{ color: "#E60000" }}>*</span>);
}


function ResetPassword({ props }) {
    const [validated, setValidated] = React.useState(false);
    const [password, setPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
    const handleMouseDown = (event) => {
        event.preventDefault();
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const payload = {
            password: password,
            token: props.resetToken,
        };
        const endpoint = `${Conf.api.applicant_api}/password`;
        const apiKey = Conf.api.xapikey;
        const config = {
            headers: {
                "x-api-key": apiKey,
                "Content-Type": "application/json"
            }
        }
        props.setWaiting(true);
        axios.patch(endpoint, payload, config)
            .then(function (response) {
                props.setWaiting(false);
                if (response.status === 200) {
                    props.setError("");
                    props.setCurrentPage("PasswordUpdated");
                }
            })
            .catch(function (error) {
                props.setWaiting(false);
                props.setError(error.response.data.message);
                setValidated(false);
                console.log(JSON.stringify(error.response.data));
            });
    };

    React.useEffect(() => {
        const validatePassword = () => {
            const numberOfAlphaNumeric = (password.match(/\w/g) || []).length;
            const numberOfUppercase = (password.match(/[A-Z]/g) || []).length;
            const numberOfNumeric = (password.match(/[\d]/g) || []).length;
            const numberOfSpecial = (password.match(/[^\w]/g) || []).length;
            if (numberOfAlphaNumeric < 4) return false;
            if (numberOfUppercase < 1) return false;
            if (numberOfNumeric < 2) return false;
            if (numberOfSpecial < 2) return false;
            if (password !== confirmPassword) return false;
            return true;
        };
        setValidated(validatePassword());
    }, [password, confirmPassword]);

    return (
        <Grid container justifyContent="center" height="100vh">
            <Grid item xs sm={6} sx={{ backgroundColor: '#FF0000' }} style={{ background: 'linear-gradient(to right bottom, #9C2AA0, #E60000)' }}>
                <Container sx={{ mt: 15, textAlign: "center" }}>
                    <TestimonialCarousel />
                </Container>
            </Grid>
            <Grid item sm={6} sx={{ backgroundColor: '#FFFFFF' }} >
                <ThemeProvider theme={theme}>
                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            <Typography sx={{ fontSize: 32, fontWeight: 500 }}>
                                Password Reset
                            </Typography>
                            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 4, width: "100%" }}>
                                <Grid container columns={18} spacing={3}>
                                    <Grid item sm={18}>
                                        <FormLabel id="label-password" sx={sxFormLabel}>Password <Star /></FormLabel>
                                        <FormControl fullWidth variant="outlined" sx={{ mt: 1 }} error={!validated}>
                                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-password"
                                                type={showPassword ? 'text' : 'password'}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDown}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                onChange={(event) => { setPassword(event.target.value) }}
                                                error={false}
                                                label="Password"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={18}>
                                        <FormLabel id="label-password" sx={sxFormLabel}>Confirm Password <Star /></FormLabel>
                                        <FormControl fullWidth variant="outlined" sx={{ mt: 1 }} error={!validated}>
                                            <InputLabel htmlFor="outlined-adornment-confirm-password">Confirm Password</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-confirm-password"
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowConfirmPassword}
                                                            onMouseDown={handleMouseDown}
                                                            edge="end"
                                                        >
                                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                onChange={(event) => { setConfirmPassword(event.target.value) }}
                                                error={false}
                                                label="Confirm Password"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={18}>
                                        <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
                                            Your password must include at least:
                                        </Typography>
                                        <Typography sx={{ fontSize: 16, fontWeight: 400, mt: 2 }}>
                                            4 Alphabetic characters <span style={{ color: "red" }}>|</span> 1 Upper case alphabetic character <span style={{ color: "red" }}>|</span> 2 Numeric characters <span style={{ color: "red" }}>|</span> 2 Special characters
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={9}>
                                        <ThemeProvider theme={changeTheme}>
                                            <Button
                                                type="button"
                                                fullWidth
                                                variant="outlined"
                                                sx={{ mt: 3, mb: 2 }}
                                                style={{ borderRadius: 68, height: 48, fontSize: 16 }}
                                                onClick={() => { props.setCurrentPage("Login") }}
                                            >
                                                Cancel
                                            </Button>
                                        </ThemeProvider>
                                    </Grid>
                                    <Grid item sm={9}>
                                        <ThemeProvider theme={submitTheme}>
                                            <Button
                                                disabled={!validated}
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                sx={{ mt: 3, mb: 2 }}
                                                style={{ borderRadius: 68, height: 48, fontSize: 16 }}
                                            >
                                                Change Password
                                            </Button>
                                        </ThemeProvider>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Container>
                </ThemeProvider>
            </Grid>
        </Grid>
    );
}

export default ResetPassword;