import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { MuiOtpInput } from 'mui-one-time-password-input'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Conf from '../../config.json';
import axios from 'axios';

const submitTheme = createTheme({
    palette: {
        primary: {
            main: '#E60000',
        }
    },
    typography: {
        button: {
            textTransform: 'none'
        }
    }
});

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: 'none',
    borderRadius: '16px',
    boxShadow: 24,
    textAlign: "center",
};

export default function OTPModal({ props }) {
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);

    const [otp, setOtp] = React.useState('')
    const handleChange = (newValue) => {
        setOtp(newValue)
    }

    const resendOtp = () => {
        const endpoint = `${Conf.api.applicant_api}/otp`;
        const apiKey = Conf.api.xapikey;
        const config = {
            headers: {
                "x-api-key": apiKey,
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
            }
        };
        props.setWaiting(true);
        axios.get(endpoint, config)
            .then(function (response) {
                if (response.status === 200) {
                    const at = response.data.accessToken;
                    localStorage.setItem("accessToken", at);
                    props.setSuccessMessage("Your One-Time Pin has been resent. Please check your messages");
                }
                props.setWaiting(false);
            })
            .catch(function (error) {
                props.setError("Failed to re-send OTP.");
                props.setWaiting(false);
            });

    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const endpoint = `${Conf.api.applicant_api}/otp`;
        const apiKey = Conf.api.xapikey;
        const config = {
            headers: {
                "x-api-key": apiKey,
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
            }
        };
        const payload = {
            "otp": otp
        };
        props.setWaiting(true);
        axios.post(endpoint, payload, config)
            .then(function (response) {
                if (response.status === 200) {
                    const at = response.data.accessToken;
                    localStorage.setItem("accessToken", at);
                    props.setLoggedIn(true);
                }
                props.setWaiting(false);
            })
            .catch(function (error) {
                console.log(JSON.stringify(error.response.data));
                props.setError(error.response.data.message);
                props.setWaiting(false);
            });
    }

    const readyToSubmit = (otp.length === 5);

    React.useEffect(() => {
        setOpen(props.open);
    }, [props, otp]);

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} component="form" noValidate onSubmit={handleSubmit}>
                    <Box sx={{ textAlign: "right", mr: 2, mt: 2 }}>
                        <IconButton onClick={handleClose}>
                            <CloseIcon sx={{ color: "#8A8D9C" }} />
                        </IconButton>
                    </Box>
                    <Box sx={{ p: 4 }}>
                        <Typography sx={{ color: "#08081B", fontSize: 26, fontWeight: 700 }}>
                            Please enter your 5-digit OTP
                        </Typography>
                        <Typography sx={{ fontSize: 16, fontWeight: 400, mt: 2 }}>
                            We've sent a One-Time PIN (OTP) to {props.partialMobile}.
                            Please enter it below to continue:
                        </Typography>
                        <Box sx={{ mt: 2 }}><MuiOtpInput length={5} value={otp} onChange={handleChange} /></Box>
                        <Typography sx={{ fontSize: 16, fontWeight: 400, mt: 2 }}>
                            <Link onClick={resendOtp}>Resend OTP</Link>
                        </Typography>
                        <ThemeProvider theme={submitTheme}>
                            <Button
                                disabled={!readyToSubmit}
                                type="submit"
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                style={{ borderRadius: 68, height: 48, fontSize: 16, width: 160 }}
                            >
                                Submit OTP
                            </Button>
                        </ThemeProvider>
                    </Box>
                </Box>
            </Modal>
        </div >
    );
}