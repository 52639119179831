import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ApplicationImage from '../../assets/Application.png'
import Grid from '@mui/material/Grid';

const submitTheme = createTheme({
    palette: {
        primary: {
            main: '#E60000',
        }
    },
    typography: {
        button: {
            textTransform: 'none'
        }
    }
});


const previousTheme = createTheme({
    palette: {
        primary: {
            main: '#000000',
        }
    },
    typography: {
        button: {
            textTransform: 'none'
        }
    }
});

function Application({ props }) {

    const handleClick = (event) => {
        props.setCurrentPage("Application Form");
    };

    React.useEffect(() => {
        if (props.user.status === "Submitted") {
            props.setCurrentPage("Submitted");
        } else {
            props.setCurrentPage("Application Form");
        }
    });

    return (
        <Box sx={{ height: "100vh", display: "flex", alignItems: "center" }} justifyContent="center">
            <Box sx={{ textAlign: "center" }}>
                <Box>
                    <img src={ApplicationImage} alt="application" style={{ maxWidth: 332 }} />
                </Box>
                <Box>
                    <Typography sx={{ fontSize: 32, fontWeight: 700, mt: 3 }}>
                        Start Application
                    </Typography>
                </Box>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item >
                            <ThemeProvider theme={previousTheme}>
                                <Button
                                    variant="outlined"
                                    sx={{ mt: 3, mb: 2, width: 200 }}
                                    style={{ borderRadius: 68, height: 48, fontSize: 16, width: 200 }}
                                    onClick={handleClick}
                                >
                                    Previous Application
                                </Button>
                            </ThemeProvider>
                        </Grid>
                        <Grid item>
                            <ThemeProvider theme={submitTheme}>
                                <Button
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2, width: 200 }}
                                    style={{ borderRadius: 68, height: 48, fontSize: 16, width: 200 }}
                                    onClick={handleClick}
                                >
                                    New Application
                                </Button>
                            </ThemeProvider>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
}

export default Application;