import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Conf from '../../config.json';
import ErrorBar from '../ErrorBar/ErrorBar';

function Star() {
    return (<span style={{ color: "#E60000" }}>*</span>);
}

const sxFormLabel = { color: "#08081B", fontSize: 16, fontWeight: 700, mb: 1 }



function PersonalDetails({ props }) {
    const [error, setError] = React.useState("");

    const handleNext = (ev) => {
        if (!props.personalDetailsValidated) {
            setError("Please complete mandatory fields");
        } else {
            props.updateBursar();
            props.setExpanded(2);
        }
    };

    React.useEffect(() => {
        
    }, [props]);
    return (
        <Box sx={{ backgroundColor: "#FFFFFF", padding: 2, borderRadius: 2, mt: 3, boxShadow: 3 }}>
            {error &&
                <ErrorBar props={{ error: error, setError: setError }} />
            }
            <Grid container columns={16} sx={{ color: "#282828" }}>
                <Grid item sm={8} sx={{ textAlign: "left" }}>
                    <Typography sx={{ fontSize: 18, fontWeight: 500 }}>
                        Personal Details
                    </Typography>
                </Grid>
                <Grid item sm={8} sx={{ textAlign: "right" }}>
                    <Typography sx={{ color: "#666666", fontSize: 16, fontWeight: 500 }}>
                        Section 1 of {props.sections}
                    </Typography>
                </Grid>
            </Grid>
            <Divider sx={{ mt: 3, mb: 3 }} />
            <Grid container columns={16} spacing={3} padding={2}>
                <Grid item sm={8} sx={{width: "100%"}}>
                    <FormControl fullWidth>
                        <FormLabel id="label-name" sx={sxFormLabel}>Name <Star /></FormLabel>
                        <TextField
                            disabled
                            fullWidth
                            variant="filled"
                            value={props.user.firstname}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={8} sx={{width: "100%"}}>
                    <FormControl fullWidth>
                        <FormLabel id="label-surname" sx={sxFormLabel}>Surname <Star /></FormLabel>
                        <TextField
                            disabled
                            fullWidth
                            variant="filled"
                            value={props.user.lastname}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={8} sx={{width: "100%"}}>
                    <FormControl fullWidth>
                        <FormLabel id="label-rsaid" sx={sxFormLabel}>ID Number <Star /></FormLabel>
                        <TextField
                            disabled
                            fullWidth
                            variant="filled"
                            value={props.user.rsaid}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={8} sx={{width: "100%"}}>
                    <FormControl fullWidth>
                        <FormLabel id="label-mobile" sx={sxFormLabel}>Cellphone Number <Star /></FormLabel>
                        <TextField
                            disabled
                            fullWidth
                            variant="filled"
                            value={props.user.mobile}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={8} sx={{width: "100%"}}>
                    <FormControl fullWidth>
                        <FormLabel id="label-email" sx={sxFormLabel}>Email Adddress <Star /></FormLabel>
                        <TextField
                            disabled
                            fullWidth
                            variant="filled"
                            value={props.user.email}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={8} sx={{width: "100%"}}>
                    <FormControl fullWidth>
                        <FormLabel id="label-gender" sx={sxFormLabel}>Gender <Star /></FormLabel>
                        <Select
                            labelId="label-gender"
                            value={props.gender}
                            error={props.gender === "none"}
                            onChange={(ev) => { props.setGender(ev.target.value) }}
                        >
                            <MenuItem key={99} value={"none"} disabled>{"-Select-"}</MenuItem>
                            {Conf.lists.gender.map((val, index) => (
                                <MenuItem key={index} value={val}>{val}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={8} sx={{width: "100%"}}>
                    <FormControl fullWidth>
                        <FormLabel id="label-nationality" sx={sxFormLabel}>Country of Birth <Star /></FormLabel>
                        <TextField
                            fullWidth
                            error={props.nationality ? false : true}
                            variant="outlined"
                            value={props.nationality}
                            onChange={(ev) => { props.setNationality(ev.target.value) }}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={8} sx={{width: "100%"}}>
                    <FormControl fullWidth>
                        <FormLabel id="label-race" sx={sxFormLabel}>Race <Star /></FormLabel>
                        <Select
                            labelId="label-race"
                            value={props.race}
                            error={props.race === "none"}
                            onChange={(ev) => { props.setRace(ev.target.value) }}
                        >
                            <MenuItem key={99} value={"none"} disabled>{"-Select-"}</MenuItem>
                            {Conf.lists.race.map((val, index) => (
                                <MenuItem key={index} value={val}>{val}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item sm={8} sx={{width: "100%"}}>
                    <FormControl fullWidth>
                        <FormLabel id="label-homelanguage" sx={sxFormLabel}>Home Language <Star /></FormLabel>
                        <Select
                            labelId="label-homelanguage"
                            value={props.homelanguage}
                            error={props.homelanguage === "none"}
                            onChange={(ev) => { props.setHomelanguage(ev.target.value) }}
                        >
                            <MenuItem key={99} value={"none"} disabled>{"-Select-"}</MenuItem>
                            {Conf.lists.language.map((val, index) => (
                                <MenuItem key={index} value={val}>{val}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={8} sx={{width: "100%"}}>
                    <FormControl fullWidth>
                        <FormLabel id="label-otherlanguage" sx={sxFormLabel}>Other Language <Star /></FormLabel>
                        <Select
                            labelId="label-otherlanguage"
                            value={props.otherlanguage}
                            error={props.otherlanguage === "none"}
                            onChange={(ev) => { props.setOtherlanguage(ev.target.value) }}
                        >
                            <MenuItem key={99} value={"none"} disabled>{"-Select-"}</MenuItem>
                            {Conf.lists.language.map((val, index) => (
                                <MenuItem key={index} value={val}>{val}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={8} sx={{width: "100%"}}>
                    <FormControl fullWidth>
                        <FormLabel id="label-addline1" sx={sxFormLabel}>Address Line 1 <Star /></FormLabel>
                        <TextField
                            fullWidth
                            error={props.addline1 ? false : true}
                            variant="outlined"
                            value={props.addline1}
                            onChange={(ev) => { props.setAddline1(ev.target.value) }}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={8} sx={{width: "100%"}}>
                    <FormControl fullWidth>
                        <FormLabel id="label-addline2" sx={sxFormLabel}>Address Line 2</FormLabel>
                        <TextField
                            fullWidth
                            variant="outlined"
                            value={props.addline2}
                            onChange={(ev) => { props.setAddline2(ev.target.value) }}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={8} sx={{width: "100%"}}>
                    <FormControl fullWidth>
                        <FormLabel id="label-city" sx={sxFormLabel}>City <Star /></FormLabel>
                        <TextField
                            fullWidth
                            error={props.city ? false : true}
                            variant="outlined"
                            value={props.city}
                            onChange={(ev) => { props.setCity(ev.target.value) }}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={8} sx={{width: "100%"}}>
                    <FormControl fullWidth>
                        <FormLabel id="label-suburb" sx={sxFormLabel}>Suburb <Star /></FormLabel>
                        <TextField
                            fullWidth
                            error={props.suburb ? false : true}
                            variant="outlined"
                            value={props.suburb}
                            onChange={(ev) => { props.setSuburb(ev.target.value) }}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={8} sx={{width: "100%"}}>
                    <FormControl fullWidth>
                        <FormLabel id="label-province" sx={sxFormLabel}>Province <Star /></FormLabel>
                        <Select
                            labelId="label-province"
                            value={props.province}
                            error={props.province === "none"}
                            onChange={(ev) => { props.setProvince(ev.target.value) }}
                        >
                            <MenuItem key={99} value={"none"} disabled>{"-Select-"}</MenuItem>
                            {Conf.lists.provinces.map((province, index) => (
                                <MenuItem key={index} value={province}>{province}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={8} sx={{width: "100%"}}>
                    <FormControl fullWidth>
                        <FormLabel id="label-postcode" sx={sxFormLabel}>Postal Code <Star /></FormLabel>
                        <TextField
                            fullWidth
                            error={props.postcode ? false : true}
                            variant="outlined"
                            value={props.postcode}
                            onChange={(ev) => { props.setPostcode(ev.target.value) }}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Box sx={{ width: "100%" }} align="right">
                <Button
                    variant="filled"
                    sx={{ mr: 3 }} style={{ borderRadius: 68, width: 100, fontSize: 16, fontWeight: 700, backgroundColor: 'black', color: 'white' }}
                    onClick={handleNext}
                >
                    Next
                </Button>
            </Box>
        </Box>
    );
}


export default PersonalDetails;