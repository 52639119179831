import React from 'react';
import Carousel from 'react-material-ui-carousel'
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Testimonial1 from '../../assets/Testimonial1.svg';
import Conf from '../../config.json';
import axios from 'axios';


function TestimonialCarousel(props) {

    const [items, setItems] = React.useState([]);

    React.useEffect(() => {
        const endpoint = `${Conf.api.applicant_api}/testimonials`;
        const apiKey = Conf.api.xapikey;
        const config = {
            headers: {
                "x-api-key": apiKey,
                "Content-Type": "application/json"
            }
        }
        axios.get(endpoint, config)
            .then(function (response) {
                if (response.status === 200) {
                    const testimonials = [...response.data];
                    var tmp = [];
                    for (var i=0; i < testimonials.length; i++) {
                        tmp.push({
                            image: testimonials[i].src || Testimonial1,
                            name: testimonials[i].name,
                            description: testimonials[i].comment
                        });
                    }
                    setItems(tmp);    
                }
            })
            .catch(function (error) {
                console.log(JSON.stringify(error.response.data));
            });

    }, []);

    /*var items = [
        {
            image: Testimonial1,
            name: "Joseph Mdaka",
            description: "I want to thank you for your generous gift that made my educations"
        },
        {
            image: Testimonial2,
            name: "MandleNkosi Mashinini",
            description: "I write code!"
        },
        {
            image: Testimonial3,
            name: "Pete",
            description: "Thanks for this great opportunity!"
        },
    ]*/

    const Item = (props) => {
        return (
            <Container width="100%">
                <img src={props.item.image} alt={props.item.name} width="60%" />
                <Typography sx={{ color: "#FFFFFF", mt: 3, fontSize: 14 }}>
                    {props.item.description}
                </Typography>
                <Typography sx={{ color: "#FFFFFF", fontWeight: 'bold', mt: 2, mb: 3, fontSize: 14 }}>
                    {props.item.name}
                </Typography>
            </Container>
        )
    };

    return (
        <Carousel>
            {
                items.map((item, i) => <Item key={i} item={item} />)
            }
        </Carousel>
    )
}

export default TestimonialCarousel;