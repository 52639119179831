import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import ErrorBar from '../ErrorBar/ErrorBar';


function Star() {
    return (<span style={{ color: "#E60000" }}>*</span>);
}

const sxFormLabel = { color: "#08081B", fontSize: 16, fontWeight: 700, mb: 1 }

function EmergencyContacts({ props }) {
    const [error, setError] = React.useState("");

    const handleNext = (ev) => {
        if (!props.emergencyContactsValidated) {
            setError("Please complete mandatory fields");
        } else {
            props.updateBursar();
            props.setExpanded(6);
        }
    };
    return (
        <Box sx={{ backgroundColor: "#FFFFFF", padding: 2, borderRadius: 2, mt: 3, boxShadow: 3 }}>
            {error &&
                <ErrorBar props={{ error: error, setError: setError }} />
            }
            <Grid container columns={16} sx={{ color: "#282828" }}>
                <Grid item sm={8} sx={{ textAlign: "left" }}>
                    <Typography sx={{ fontSize: 18, fontWeight: 500 }}>
                        Emergency Contacts
                    </Typography>
                </Grid>
                <Grid item sm={8} sx={{ textAlign: "right" }}>
                    <Typography sx={{ color: "#666666", fontSize: 16, fontWeight: 500 }}>
                        Section 5 of {props.sections}
                    </Typography>
                </Grid>
            </Grid>
            <Divider sx={{ mt: 3, mb: 3 }} />
            <Grid container columns={16} spacing={3} padding={2}>
                <Grid item sm={8}>
                    <FormControl fullWidth>
                        <FormLabel id="label-emergencycontactname" sx={sxFormLabel}>Emergency Contact Person<Star /></FormLabel>
                        <TextField
                            required
                            fullWidth
                            error={props.emergencycontactname ? false : true}
                            label="Enter emergency contact person"
                            value={props.emergencycontactname}
                            onChange={(ev) => { props.setEmergencycontactname(ev.target.value) }}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={8}>
                    <FormControl fullWidth>
                        <FormLabel id="label-emergencycontactrelationship" sx={sxFormLabel}>Emergency Contact Relationship<Star /></FormLabel>
                        <TextField
                            required
                            fullWidth
                            error={props.emergencycontactrelationship ? false : true}
                            label="Enter relationship"
                            value={props.emergencycontactrelationship}
                            onChange={(ev) => { props.setEmergencycontactrelationship(ev.target.value) }}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={8}>
                    <FormControl fullWidth>
                        <FormLabel id="label-emergencycontactnumber" sx={sxFormLabel}>Emergency Contact No.<Star /></FormLabel>
                        <TextField
                            required
                            fullWidth
                            error={!(props.emergencycontactnumber.match(/^0\d{9}$/) || props.emergencycontactnumber.match(/^\+\d{11}$/))}
                            label="Enter emergency contact number"
                            value={props.emergencycontactnumber}
                            onChange={(ev) => { props.setEmergencycontactnumber(ev.target.value) }}
                            type="number"
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={8}>
                    &nbsp;
                </Grid>
                <Grid item sm={8}>
                    <FormControl fullWidth>
                        <FormLabel id="label-emergencycontactdisability" sx={sxFormLabel}>Do you have a mental/ physical disability<Star /></FormLabel>
                        <Select
                            labelId="label-emergencycontactdisability"
                            value={props.emergencycontactdisability}
                            error={props.emergencycontactdisability === "none"}
                            onChange={(ev) => { props.setEmergencycontactdisability(ev.target.value) }}
                        >
                            <MenuItem key={99} value={"none"} disabled>{"-Select-"}</MenuItem>
                            <MenuItem key={0} value={"No"}>No</MenuItem>
                            <MenuItem key={1} value={"Yes"}>Yes</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {(props.emergencycontactdisability === "Yes") &&
                    <Grid item sm={16}>
                        <FormControl fullWidth>
                            <FormLabel id="label-emergencycontactdisabilitydesc" sx={sxFormLabel}>If you have a mental/ physical disability, please specify</FormLabel>
                            <TextField
                                multiline
                                rows={4}
                                fullWidth
                                label="specify"
                                value={props.emergencycontactdisabilitydesc}
                                onChange={(ev) => { props.setEmergencycontactdisabilitydesc(ev.target.value) }}
                                error={props.emergencycontactdisabilitydesc ? false : true}
                            />
                        </FormControl>
                    </Grid>
                }
                <Grid item sm={8}>
                    <FormControl fullWidth>
                        <FormLabel id="label-emergencycontactconvicted" sx={sxFormLabel}>Have you been convicted of any crime/s<Star /></FormLabel>
                        <Select
                            labelId="label-emergencycontactconvicted"
                            value={props.emergencycontactconvicted}
                            error={props.emergencycontactconvicted === "none"}
                            onChange={(ev) => { props.setEmergencycontactconvicted(ev.target.value) }}
                        >
                            <MenuItem key={99} value={"none"} disabled>{"-Select-"}</MenuItem>
                            <MenuItem key={0} value={"No"}>No</MenuItem>
                            <MenuItem key={1} value={"Yes"}>Yes</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {(props.emergencycontactconvicted === "Yes") &&
                    <Grid item sm={16}>
                        <FormControl fullWidth>
                            <FormLabel id="label-emergencycontactconvicteddescn" sx={sxFormLabel}>If conviced of any crimes/s, please specify</FormLabel>
                            <TextField
                                multiline
                                rows={4}
                                fullWidth
                                label="specify"
                                value={props.emergencycontactconvicteddescn}
                                error={props.emergencycontactconvicteddescn ? false : true}
                                onChange={(ev) => { props.setEmergencycontactconvicteddescn(ev.target.value) }}
                            />
                        </FormControl>
                    </Grid>
                }
            </Grid>
            <Box sx={{ width: "100%" }} align="right">
                <Button
                    variant="filled"
                    sx={{ mr: 3 }} style={{ borderRadius: 68, width: 100, fontSize: 16, fontWeight: 700, backgroundColor: 'black', color: 'white' }}
                    onClick={handleNext}
                >
                    Next
                </Button>
            </Box>
        </Box>
    );
}


export default EmergencyContacts;