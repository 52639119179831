import Grid from '@mui/material/Grid';
import ErrorIcon from '@mui/icons-material/Error';
import Button from '@mui/material/Button';

function ErrorBar({ props }) {
    return (
        <Grid container alignItems="center" direction="row" sx={{ backgroundColor: '#FECB00', padding: 2 }}>
            <Grid item xs={10} justifyContent='flex-start' sx={{ fontSize: 14, fontWeight: 'bold', color: 'inherit', textTransform: 'none', padding: 0, display: 'inline-flex' }}>
                <ErrorIcon style={{ marginLeft: 50, marginRight: 10 }} /><span style={{ marginTop: 2 }}>{props.error}</span>
            </Grid>
            <Grid item xs={1} justifyContent='flex-end'>
                <Button onClick={() => { props.setError("") }}
                    sx={{ fontSize: 14, fontWeight: 'bold', color: 'inherit', textTransform: 'none', padding: 0 }}
                >Dismiss</Button>
            </Grid>
        </Grid>
    );
}

export default ErrorBar;